
export const COIN_SEQUENCE = ['GRAM', 'BTC', 'RVN', 'ETC', 'DNX', 'KAS'];

export const DEFAULT_COIN = COIN_SEQUENCE[4];

export const VIEWS = {
  WORKERS: 'workers',
  PAYOUTS: 'payouts',
}

export const NETWORK_COINS = {
  BTC: 'BTC',
  GRAM: 'TON',
  ETC: 'ETC',
  // RVN: 'RVN',
}
// Todo: need uncommited when we will transfer the RVN to account mining
export const COIN_WITH_SUBACCOUNTS = ['GRAM', 'BTC', 'ETC', 'KAS', 'DNX' ]; // 'RVN'

export const COIN_ROUTES = {
  '': 'ETC',
  'etc': 'ETC',
  // 'ethereum-classic': 'ETC',
  'gram': 'GRAM',
  'btc': 'BTC',
  'rvn': 'RVN',
  'ravencoin': 'RVN',
  'kas': 'KAS',
  'kaspa': 'KAS',
  'dnx-mining-pool': 'DNX',
};

export const COIN_LANDING = {
  'etc': '',
  // 'etc': 'ethereum-classic',
  'gram': 'gram',
  'btc': 'btc',
  'rvn': 'ravencoin',
  'kas': 'kaspa',
  'dnx': 'dnx-mining-pool',
};

export const ACCOUNT_PATHES = {
  gram: 'gram',
  etc: 'etc',
  btc: 'btc',
  rvn: 'rvn',
  kas: 'kas',
  dnx: 'dnx-mining-pool',
};
